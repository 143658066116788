<template>
    <div>
        <jy-query labelWidth="100" class="query_area" size="small">
            <jy-query-item label="所属行政区域">
                <div @click="selectRegion">
                    <el-input placeholder="请选择" v-model="form.regionNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="固废类别">
                <el-select v-model="form.wasteTId">
                    <el-option
                        v-for="t in wasteTypes"
                        :key="t.wasteTId"
                        :label="t.cName"
                        :value="t.wasteTId"
                    ></el-option>
                </el-select>
            </jy-query-item>
        </jy-query>
        <jy-homesum :list="summary1" class="text_box home_item"></jy-homesum>
        <jy-homesum :list="summary2" class="text_box home_item"></jy-homesum>

        <div class="module_name">行政区域产废企业数量</div>
        <center-map :data-list="unitInfoWasteRegion" @update="getUnitInfoWasteRegion"></center-map>
        <div class="module_name">企业产废统计排名</div>
        <jy-query labelWidth="100" class="query_area" size="small">
            <jy-query-item label="所属行业">
                <el-select v-model="form.industry">
                    <el-option
                        v-for="p in industryList"
                        :key="p.iValue"
                        :value="p.iValue"
                        :label="p.iValue"
                    ></el-option>
                </el-select>
            </jy-query-item>
        </jy-query>
        <rubbish-rank :data-list="wasteUnitStatList"></rubbish-rank>
        <div class="module_name">每月企业产废统计</div>
        <rubbish-count :dataList="wasteMonthList" :regionId="form.regionId" :regionNa="form.regionNa"></rubbish-count>
        <div class="module_name">行政区域固废流向统计</div>
         <div style="text-align: right;">
             <el-radio-group v-model="date">
                <el-radio-button label="日"></el-radio-button>
                <el-radio-button label="周"></el-radio-button>
                <el-radio-button label="月"></el-radio-button>
            </el-radio-group>
        </div>
        <region-flow-count :data-list="receiveForm"></region-flow-count>
        <div class="module_name">固废减量分析</div>
        <decrement-count :data-list="receiveFormYear"></decrement-count>
        <div class="module_name">分拣中心固废流向</div>
        <rubbish-flow :data-list="wasteLife"></rubbish-flow>

        <!-- <map-chart></map-chart>
        <rubbish-flow></rubbish-flow>-->
        <region-tree ref="region" @select="addRegion"></region-tree>

        <institutions-tree
                refresh
                :showType="showType"
                :expended="expended"
                :modal="false"
                ref="institutions"
                @addInstitutions="addInstitutions"
                title="选择所属机构"
            ></institutions-tree>
    </div>
</template>

<script>
import img1 from "@/assets/home/depart/img1.png";
import img2 from "@/assets/home/depart/img2.png";
import img3 from "@/assets/home/depart/img3.png";
import img4 from "@/assets/home/depart/img4.png";
import img5 from "@/assets/home/depart/img5.png";
import img6 from "@/assets/home/depart/img6.png";
import img7 from "@/assets/home/depart/img7.png";
import img8 from "@/assets/home/depart/img8.png";
import img9 from "@/assets/home/depart/img9.png";
import img10 from "@/assets/home/depart/img10.png";

import CenterMap from "./CenterMap.vue";
import RubbishFlow from "./RubbishFlow.vue";
import DecrementCount from "./DecrementCount.vue";
import RegionFlowCount from "./RegionFlowCount.vue";
import RubbishCount from "./RubbishCount.vue";
import RubbishRank from "./RubbishRank.vue";

import RegionTree from "@/components/pages/admin/common/dialog-tree/region_tree.vue";
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";

export default {
    created() {
        this.init();
    },
    data() {
        return {
            showType: [0, 1, 2],
            expended: true,
            summary1: [
                {
                    icon: img1,
                    info: "产废企业",
                    num: "0",
                    unit: "个"
                },
                {
                    icon: img2,
                    info: "环服公司",
                    num: "0",
                    unit: "个"
                },
                {
                    icon: img3,
                    info: "分拣中心",
                    num: "0",
                    unit: "个"
                },
                {
                    icon: img4,
                    info: "运输车辆",
                    num: "0",
                    unit: "个"
                },
                {
                    icon: img5,
                    info: "运输司机",
                    num: "0",
                    unit: "个"
                }
            ],
            summary2: [
                {
                    icon: img6,
                    info: "产废接收量",
                    num: "0",
                    unit: "kg"
                },
                {
                    icon: img7,
                    info: "回收利用量",
                    num: "0",
                    unit: "kg"
                },
                {
                    icon: img8,
                    info: "处置量",
                    num: "0",
                    unit: "kg"
                },
                {
                    icon: img9,
                    info: "回收利用率",
                    num: "0",
                    unit: "%"
                },
                {
                    icon: img10,
                    info: "节能减排",
                    num: "0",
                    unit: "kg"
                }
            ],

            date: '日',
            dateNum: '0',
            form: {
                wasteTId: null,
                regionId: null,
                regionNa: null,
                industry: null
            },

            wasteUnitStatList: [],
            wasteMonthList:[],
            receiveForm: {},
            receiveFormYear: {},
            wasteLife: {},
            unitInfoWasteRegion: [],

            wasteTypes: [],
            industryList: [],
        };
    },
    methods: {
        init() {
            this.form.regionId = this.$store.state.info.regionId || 100000;
            this.form.regionNa = this.$store.state.info.regionNa || '';
            this.getWasteTypes();
            this.getIndustryList();
        },
        getWasteTypes() {
            return this.$http
                .post("/wasteType/listAll")
                .then(res => {
                    this.wasteTypes = res.detail;
                    this.form.wasteTId = this.wasteTypes[0].wasteTId;
                })
        },
        getIndustryList() {
            this.$http
                .post("/dict/getDictValuesByDictId", "dictindustry")
                .then(res => {
                    this.industryList = res.detail;
                });
        },
        getCountMsgCom() {
            this.$http
                .post('/home/supervision/countMsgCom', {
                    ...this.form
                })
                .then(res => {
                    this.setSummary(res.detail);
                })
        },
        getUnitInfoWasteRegion(_regionId) {
            const regionId = _regionId || this.form.regionId
            this.$http
                .post('/home/supervision/unitInfoWasteRegion', {
                    regionId
                })
                .then(res => {
                    this.unitInfoWasteRegion = res.detail;
                    res.detail.forEach(one=>{
                        one.sumWasteToT = (one.sumWaste / 1000).toFixed(1)
                    })
                })
        },
        getWasteUnitStat() {
            this.$http
                .post('/home/supervision/wasteUnitStat', {
                    ...this.form,
                    sort: 'desc',
                    sortColumn: '0'
                })
                .then(res => {
                    this.wasteUnitStatList = res.detail;
                })
        },
        getWasteMonthList() {
            this.$http
                .post('/home/supervision/wasteMonthList', {
                    ...this.form,
                })
                .then(res => {
                    this.wasteMonthList = res.detail;
                })
        },
        getReceiveForm(type) {
            this.$http
                .post('/home/supervision/receiveForm', {
                    ...this.form,
                    dateLevel: type
                })
                .then(res => {
                    console.log(res, 'res')
                    if (type !== '3') {
                        this.receiveForm = res.detail;
                    } else {
                        this.receiveFormYear = res.detail;
                    }
                })
        },
        getWasteLife() {
            this.$http
                .post('/home/supervision/wasteLife', {
                    ...this.form,
                })
                .then(res => {
                    this.wasteLife = res.detail
                })
        },
        formatPercent(percent) {
            if (isNaN(percent)) {
                return 0;
            }
            const _percent = percent * 100;
            return _percent <= 0
                ? _percent.toString()
                : '+' + _percent.toString();
        },
        setSummary(data) {
            this.summary1 = [
                {
                    icon: img1,
                    info: "产废企业",
                    num: data.unit0,
                    unit: "个"
                },
                {
                    icon: img2,
                    info: "环服公司",
                    num: data.countOrg,
                    unit: "个"
                },
                {
                    icon: img3,
                    info: "分拣中心",
                    num: data.unit3,
                    unit: "个"
                },
                {
                    icon: img4,
                    info: "运输车辆",
                    num: data.vehicle,
                    unit: "个"
                },
                {
                    icon: img5,
                    info: "运输司机",
                    num: data.driver,
                    unit: "个"
                }
            ];

            this.summary2 = [
                {
                    icon: img6,
                    info: "产废接收量",
                    num: data.wastes,
                    percent: this.formatPercent(data.wastesPercent),
                    unit: "kg"
                },
                {
                    icon: img7,
                    info: "回收利用量",
                    num: data.stockOut,
                    percent: this.formatPercent(data.stockOutPercent),
                    unit: "kg"
                },
                {
                    icon: img8,
                    info: "处置量",
                    num: data.deal,
                    percent: this.formatPercent(data.dealPercent),
                    unit: "kg"
                },
                {
                    icon: img9,
                    info: "回收利用率",
                    num: data.percent,
                    unit: "%"
                },
                {
                    icon: img10,
                    info: "节能减排",
                    num: 0,
                    unit: "kg"
                }
            ];
        },
        selectRegion() {
            const { regionId: pid = 0 } = this.$store.state.info;
            this.$refs.region.init({ pid });
        },
        addRegion(data) {
            this.form.regionId = data.regionId;
            this.form.regionNa = data.regionNa;
        },
        addInstitutions(data) {
            this.form.orgId = data.orgId;
            this.form.orgNa = data.orgNa;
        },
        // 选择机构树
        selectInstitutions() {
            this.showType = [1];
            this.expended = false;
            this.$refs.institutions.init({ flag: "org" });
        },
    },
    watch: {
        "form.regionId": {
            async handler() {
                if (!this.wasteTypes.length) {
                    await this.getWasteTypes();
                }
                this.getCountMsgCom();
                this.getUnitInfoWasteRegion();
                this.getWasteMonthList();
                this.getReceiveForm(this.dateNum);
                this.getReceiveForm('3');
                this.getWasteLife();
            }
        },
        "form.industry": {
            handler() {
                this.getWasteUnitStat();
            }
        },
        date(newValue) {
            switch (newValue) {
                case '日':
                    this.dateNum = '0';
                    break;
                case '周':
                    this.dateNum = '1';
                    break;
                case '月':
                    this.dateNum = '2';
                    break;
            }
            this.getReceiveForm(this.dateNum);
        }
    },
    components: {
        CenterMap,
        RubbishFlow,
        DecrementCount,
        RegionFlowCount,
        RubbishCount,
        RubbishRank,
        RegionTree,
        institutionsTree
    }
};
</script>
<style scoped>
.module_name {
    font-size: 14px;
    font-weight: bold;
    color: #4786e3;
    margin: 10px 0;
}

.module_name::before {
    content: "";
    display: inline-block;
    width: 3px;
    background-color: #4786e3;
    height: 16px;
    vertical-align: middle;
    margin-right: 3px;
}
.home_item {
    margin-bottom: 15px;
}
</style>
