<template>
    <div class="type_stock">
        <div id="type_stock_chart"></div>
    </div>
</template>

<script>
    import * as Echarts from "echarts";
    export default {
        props: {
            dataList: {
                type: Array,
                default: null
            }
        },
        watch: {
            'dataList': {
                handler() {
                    this.draw();
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.draw();
            });
        },
        methods: {
            draw() {
                const dom = document.querySelector("#type_stock_chart");
                const keys = this.dataList.map(v => v.dateStr);
                const values = this.dataList.map(v => v.value);
                console.log(this.dataList)
                const eIns = Echarts.init(dom);
                const option = {
                    grid: {
                        top: "8%",
                        left: "5%",
                        right: "5%",
                        bottom: "10%"
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: false,
                        data: keys
                    },
                    yAxis: {
                        type: "value",
                        name: '千克/方',
                        show: true,
                        axisLine: {
                            show: true
                        },
                        axisTick: {
                            show: true
                        },
                        splitLine: {
                            show: false
                        },
                        splitArea: {
                            show: true,
                            areaStyle: {
                                color: ["rgba(71, 134, 227, 0.1)", "rgba(71, 134, 227, 0.05)"]
                            }
                        }
                    },
                    series: [
                        {
                            data: values,
                            type: "line",
                            areaStyle: {
                                color: "#4CC9FF",
                                opacity: 0.4
                            },
                            smooth: true
                        }
                    ]
                };
                eIns.setOption(option);
            }
        }
    };
</script>
<style lang="scss" scoped>
    #type_stock_chart {
        height: 350px;
    }
</style>
