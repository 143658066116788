<template>
    <div>
        <el-table :data="dataList" style="width: 100%">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="unitName" label="产废企业">
                <template v-slot="scope">{{ scope.row.unitNa }}</template>
            </el-table-column>
            <el-table-column label="年产废量">
                <el-table-column prop="weight" label="年度产废量">
                    <template v-slot="scope">
                        <div>{{ scope.row.yearSum }}kg</div>
                        <div>同比去年 {{ formatPercent(scope.row.yearPercent) }}%</div>
                    </template>
                </el-table-column>
                <el-table-column label="去年同期" prop="lWeight">
                    <template v-slot="scope">{{ scope.row.yearSumPre }}kg</template>
                </el-table-column>
            </el-table-column>
            <el-table-column label="月产废量">
                <el-table-column prop="mWeight" label="上月产废量">
                    <template v-slot="scope">
                        <div>{{ scope.row.monthSum }}kg</div>
                        <div>同比去年 {{ formatPercent(scope.row.monthPercent) }}%</div>
                    </template>
                </el-table-column>
                <el-table-column label="去年同期" prop="lMWeight">
                    <template v-slot="scope">{{ scope.row.monthSumPre }}kg</template>
                </el-table-column>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        props: {
            dataList: {
                type: Array,
                default: null
            },
        },
        methods: {
            formatPercent(percent) {
                if (isNaN(percent)) {
                    return 0;
                }
                const _percent = percent * 100;
                return _percent <= 0
                    ? _percent.toString()
                    : '+' + _percent.toString();
            }
        },
        data() {
            return {
                tableData: [
                    {
                        unitName: "超威电池有限公司",
                        weight: "年度产废量 369574 kg   同比去年↑54.63% 369574 kg",
                        lWeight: "去年同期 156984 kg",
                        mWeight: "上月产废量 9574 kg   同比去年↑54.63% 9165 kg",
                        lMWeight: "4365 kg"
                    },
                    {
                        unitName: "超威电池有限公司",
                        weight: "年度产废量 369574 kg   同比去年↑54.63% 369574 kg",
                        lWeight: "去年同期 156984 kg",
                        mWeight: "上月产废量 9574 kg   同比去年↑54.63% 9165 kg",
                        lMWeight: "4365 kg"
                    },
                    {
                        unitName: "超威电池有限公司",
                        weight: "年度产废量 369574 kg   同比去年↑54.63% 369574 kg",
                        lWeight: "去年同期 156984 kg",
                        mWeight: "上月产废量 9574 kg   同比去年↑54.63% 9165 kg",
                        lMWeight: "4365 kg"
                    },
                    {
                        unitName: "超威电池有限公司",
                        weight: "年度产废量 369574 kg   同比去年↑54.63% 369574 kg",
                        lWeight: "去年同期 156984 kg",
                        mWeight: "上月产废量 9574 kg   同比去年↑54.63% 9165 kg",
                        lMWeight: "4365 kg"
                    }
                ]
            };
        }
    };
</script>
<style scoped>
    .el-table {
        text-align: center;
    }
</style>
