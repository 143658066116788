<template>
    <div>
        <div ref="flowChart" class="flow-chart"></div>
    </div>
</template>

<script>
import * as Echarts from "echarts";
export default {
    props: {
        dataList: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            nameList: [],
            linkList: []
        }
    },
    watch: {
        dataList() {
            const map = new Map();

            const { unitWasteList } = this.dataList;
            if (unitWasteList && unitWasteList.length) {
                this.nameList.push({ name: '固废' })
                for (const unitWaste of unitWasteList) {
                    this.nameList.push({ name: unitWaste.tUnitNa })
                    this.linkList.push({
                        source: '固废',
                        target: unitWaste.tUnitNa,
                        value: unitWaste.sum
                    })
                    map.set(unitWaste.tUnitNa, unitWaste.sum);
                }
            }

            const { useList } = this.dataList;
            if (useList && useList.length) {
                this.nameList.push({ name: '利用' }, { name: '不可利用' })
                for (const unitWaste of useList) {
                    this.linkList.push({
                        source: unitWaste.unitNa,
                        target: '利用',
                        value: unitWaste.sum
                    }, {
                        source: unitWaste.unitNa,
                        target: '不可利用',
                        value: map.get(unitWaste.unitNa) - unitWaste.sum
                    })
                }
            }

            const { dealList } = this.dataList;
            if (dealList && dealList.length) {
                for (const unitWaste of dealList) {
                    this.nameList.push({ name: unitWaste.unitNa })
                    this.linkList.push({
                        source: "不可利用",
                        target: unitWaste.unitNa,
                        value: unitWaste.sum
                    })
                }
            }

            map.clear();

            this.draw();
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.draw();
        });
    },
    methods: {
        draw() {
            const dom = this.$refs.flowChart;
            const eIns = Echarts.init(dom);
            const option = {
                tooltip: {
                    trigger: 'item',
                    triggerOn: 'mousemove'
                },
                series: {
                    type: "sankey",
                    layout: "none",
                    nodeAlign: "left",
                    emphasis: {
                        focus: "adjacency"
                    },
                    data: this.nameList,
                    links: this.linkList
                    // data: [
                    //     {
                    //         name: "固废"
                    //     },
                    //     {
                    //         name: "A分拣中心"
                    //     },
                    //     {
                    //         name: "B分拣中心"
                    //     },
                    //     {
                    //         name: "利用"
                    //     },
                    //     {
                    //         name: "不可利用"
                    //     },
                    //     {
                    //         name: "A处置单位"
                    //     },
                    //     {
                    //         name: "B处置单位"
                    //     }
                    // ],
                    // links: [
                    //     {
                    //         source: "固废",
                    //         target: "B分拣中心",
                    //         value: 3
                    //     },
                    //     {
                    //         source: "固废",
                    //         target: "A分拣中心",
                    //         value: 5
                    //     },
                    //     {
                    //         source: "A分拣中心",
                    //         target: "利用",
                    //         value: 1
                    //     },
                    //     {
                    //         source: "A分拣中心",
                    //         target: "不可利用",
                    //         value: 4
                    //     },
                    //     {
                    //         source: "B分拣中心",
                    //         target: "利用",
                    //         value: 1
                    //     },
                    //     {
                    //         source: "B分拣中心",
                    //         target: "不可利用",
                    //         value: 2
                    //     },
                    //     {
                    //         source: "不可利用",
                    //         target: "A处置单位",
                    //         value: 1
                    //     },
                    //     {
                    //         source: "不可利用",
                    //         target: "B处置单位",
                    //         value: 2
                    //     }
                    // ]
                }
            };
            eIns.setOption(option);
        }
    }
};
</script>
<style scoped>
.flow-chart {
    height: 350px;
}
</style>
