<template>
    <div>
        <div class="summary_chart" ref="summary_chart"></div>
    </div>
</template>

<script>
    import * as Echarts from "echarts";
    export default {
        props: {
            dataList: {
                type: Object,
                default: null
            }
        },
        watch: {
            'dataList': {
                handler() {
                    this.$nextTick(() => {
                        this.draw();
                    });
                }
            }
        },
        mounted() {
            if (Object.keys(this.dataList).length) {
                    this.$nextTick(() => {
                        this.draw();
                    });
            }
        },
        methods: {
            draw() {
                if (!this.rendered) {
                    this.$nextTick(() => {
                        const c = Echarts.init(this.$refs.summary_chart);
                        const option = {
                            tooltip: {
                                trigger: "axis",
                                axisPointer: {
                                    type: "shadow"
                                },
                                formatter: (params) => {
                                    return params
                                        .map((v, i) => v.marker + v.seriesName + ': ' + v.value + (i === 2 ? '%' : ''))
                                        .join('<br/>')
                                }
                            },
                            legend: {
                                right: 10
                            },
                            grid: {
                                left: "5%",
                                right: "5%",
                                bottom: "10%",
                                top: "10%"
                            },
                            xAxis: {
                                type: "category",
                                data: this.dataList.keyList || []
                            },
                            yAxis: [{
                                type: "value",
                                show: true,
                                axisLine: {
                                    show: true
                                },
                                axisTick: {
                                    show: true
                                },
                                splitLine: {
                                    show: false
                                },
                                splitArea: {
                                    show: true,
                                    areaStyle: {
                                        color: ["rgba(71, 134, 227, 0.1)", "rgba(71, 134, 227, 0.05)"]
                                    }
                                }
                            }, {
                                type: 'value',
                                max: 100,
                                min: -100,
                                axisLabel: {
                                    show: true,
                                    interval: 'auto',
                                    formatter: '{value}%'
                                }
                            }],
                            series: [
                                {
                                    name: "回收利用",
                                    type: "bar",
                                    data: this.dataList.receiverList || [],
                                    yAxisIndex: 0,
                                },
                                {
                                    name: "处置量",
                                    type: "bar",
                                    data: this.dataList.dealList || [],
                                    yAxisIndex: 0,
                                },
                                {
                                    name: "减产率",
                                    type: "line",
                                    data: this.dataList.percentList.map(v => v * 100) || [],
                                    yAxisIndex: 1,
                                }
                            ]
                        };

                        c.setOption(option);
                        this.rendered = true;
                    });
                }
            }
        },
        data() {
            return {
                rendered: false
            };
        }
    };
</script>
<style lang="scss" scoped>
    .summary_chart {
        height: 350px;
    }
</style>
